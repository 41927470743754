import React, {  useState } from 'react';
import { HeadingFour, MyDiv,  SpanTag } from '../Common/Components';
import { Link, useLocation } from 'react-router-dom';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import { RiMenuUnfoldLine } from "react-icons/ri";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;



function PatientSideBar() {
    const user = useSelector((state) => state.user);
    const pathname = useLocation().pathname;
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <React.Fragment>
            <MyDiv className={`LeftSideBarToggleIcon ${isSidebarOpen ? '' : 'open'}`} >
                <RiMenuUnfoldLine onClick={toggleSidebar} />
            </MyDiv>
            <MyDiv className={`leftSideBarOuter ${isSidebarOpen ? '' : 'open'}`}>
            <MyDiv className="SideBarProfile">
                <SpanTag className="ProfileImage">
                    <Avatar size="150" name={user.username} src={`${API_BASE_URL}${user.profilePhoto}`} />
                </SpanTag>
                <HeadingFour className='mb-3'>{user.username}</HeadingFour>
                {/* <PTag>{calculateAge(doctor.doc_DOB)}, {doctor.doc_Contact_Info.city}</PTag> */}
                <Link to="/patient/edit-profile" >Edit Profile</Link>
            </MyDiv>
            <MyDiv className="SideBarMenu">
                <ul>
                    <li className={`${pathname.startsWith('/patient/dashboard') ? "menuActive" : ""}`}><Link to="/patient/dashboard">Dashboard</Link></li>
                    <li className={`${pathname.startsWith('/patient/my-appointment') ? "menuActive" : ""}`}><Link to="/patient/my-appointment">My Appointments</Link></li>

                </ul>
            </MyDiv>
            </MyDiv>
        </React.Fragment>
    );
}

export default PatientSideBar;